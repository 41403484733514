@import url('https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap');
@import '~react-image-gallery/styles/css/image-gallery.css';

@font-face {
  font-family: 'Futura-Md-Medium';
  src:
    local('Futura-Md-Medium'),
    url(./fonts/Futura-Md-Medium.ttf) format('truetype');
}

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  background-color: #3e4357;
  font-family: 'Oxygen', sans-serif;
  overflow-x: hidden;
  background-image: url('./assests/background/zig-zag.png');
}

#root {
  display: flex;
  flex-direction: column;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Futura-Md-Medium';
}

.container-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
  color: white;
}

.fallback {
  height: 100vh;
}

/* override image-gallery style */
.photoGalleryThumbnail.active,
.photoGalleryThumbnail:hover,
.photoGalleryThumbnail:focus {
  border-color: #a9c5e7;
}

.image-gallery-icon:hover {
  color: #a9c5e7;
}

.image-gallery-icon:focus {
  outline-color: #a9c5e7;
}
