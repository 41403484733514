.emptySpace {
  height: 80px;
  flex-shrink: 0;
  background-color: #3a3d51;
}

.spotify {
  position: fixed;
  bottom: 0;
  z-index: 5;
}
