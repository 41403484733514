main {
  max-width: 1600px;
  width: 100%;
  box-sizing: border-box;
  margin: auto;
  padding: 0 20px;
  flex: 1 0 auto;
}

@media only screen and (max-width: 600px) {
  main {
    padding: 0;
  }
}
