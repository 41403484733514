.socialMedias {
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

.socialMedias a {
  display: inline-block;
  height: 100%;
  text-align: center;
  text-decoration: none;
  color: white;
  transition: 600ms;
}

.socialMedias a:hover {
  color: #a9c5e7;
}

.socialMedias svg {
  height: 100%;
  width: auto;
}
