footer {
  position: relative;
  width: 100%;
  height: auto;
  background-color: #51577c;
  flex-shrink: 0;
  margin-top: 1em;
}

.footerTop {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  color: white;
  margin: 10px 0 40px 0;
}

.logo {
  max-width: 100%;
  width: 250px;
  height: auto;
  vertical-align: middle;
}

footer a {
  text-decoration: none;
  color: white;
  text-transform: uppercase;
  transition: 600ms;
}

footer a:hover {
  color: #a9c5e7;
  text-decoration: underline;
}

.footerContainer {
  width: calc(100% / 3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0;
  padding: 0;
}

.links li {
  display: inline;
  margin: 6px 0;
}

.mediaTitle {
  text-transform: uppercase;
  margin-bottom: 10px;
  font-weight: bold;
  color: #cfd0d5;
}

.socialMediasContainer {
  height: 35px;
  width: 170px;
}

.footerBottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #3a3d51;
  color: white;
  font-size: 0.85em;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .footerTop {
    flex-direction: column;
  }

  .footerContainer {
    width: 100%;
  }

  .footerContainer:not(:last-child) {
    padding-bottom: 20px;
  }
}
