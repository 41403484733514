header {
  margin-bottom: 1em;
}

.headerTop {
  width: 100%;
  height: auto;
  padding: 2px 0;
  background-color: #a9c5e7;
  text-align: center;
}

.headerTop a {
  color: #3a3d51;
  text-decoration: none;
}

.header {
  width: 100%;
  height: 60px;
  color: white;
  background-color: #3a3d51;
  text-align: center;
  position: relative;
}

.bars {
  height: 40px;
  width: 40px;
  cursor: pointer;
  position: absolute;
  left: 10px;
  top: 10px;
}

.logoLink {
  display: inline-block;
  height: 100%;
}

.logo {
  height: 50px;
  margin: 5px 0;
}

.headerContainer {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  z-index: 6;
  background-color: rgba(84, 84, 84, 0.5);
}

.headerContent {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 320px;
  overflow: hidden;
  z-index: 7;
  color: white;
  background-color: #3a3d51;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.headerContent .container {
  width: 100%;
  text-align: center;
  margin: 1em 0;
}

.headerContent .container:first-child {
  margin: 0;
}

.times {
  width: 40px;
  height: 40px;
  cursor: pointer;
  float: right;
  margin: 10px 10px 0 0;
}

.navLink {
  text-decoration: none;
  font-family: 'Futura-Md-Medium';
  font-size: 1.6em;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
}

.socialMediasContainer {
  height: 50px;
  width: 80%;
  margin: auto;
}

.contact {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.5em;
  text-transform: uppercase;
  color: white;
  font-weight: bold;
  font-size: 1.5em;
  text-decoration: none;
}

.contact svg {
  height: 100%;
  width: auto;
  margin-right: 10px;
}

.logoSmall {
  width: 100px;
}

.button {
  background-color: #a9c5e7;
  position: relative;
  display: block;
  margin: auto;
  padding: 15px 5px;
  text-align: center;
  font-weight: bold;
  width: 170px;
  overflow: hidden;
  cursor: pointer;
  letter-spacing: 2px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  transition: all ease 600ms;
  color: white;
  text-shadow: 0 1px 1px #3a3d51;
}

.button::before {
  width: 180px;
  height: 100%;
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: -100%;
  cursor: pointer;
  background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.25), transparent);
  transition: all ease 1s;
}

.button:hover {
  text-shadow: 1px 1px 1px #3a3d51;
}

.button:hover::before {
  left: 115%;
}

@media only screen and (max-height: 570px) {
  .headerContent {
    font-size: 0.9em;
  }
  .headerContent .container:last-child {
    margin: 0;
  }
}
